import {AfterViewInit, Component, ElementRef, inject, Input, ViewChild} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {LoginComponent} from "../../login/login.component";
import {Modal} from "bootstrap";
import {NgIf} from "@angular/common";
import {CheckoutComponent} from "../checkout.component";
import {Selection} from "../shopping-card";
import {CheckoutModalService} from "./checkout-modal.service";
import { AuthenticationService } from '../../authentication/authentication.service';
import {LoginMemoryService} from "../../login/login-memory.service";

@Component({
  selector: 'app-checkout-modal',
  standalone: true,
  imports: [
    FormsModule,
    LoginComponent,
    NgIf,
    CheckoutComponent
  ],
  templateUrl: './checkout-modal.component.html',
  styleUrl: './checkout-modal.component.scss'
})
export class CheckoutModalComponent implements AfterViewInit {

  private loginMemoryService = inject(LoginMemoryService);

  selection!: Selection;

  @ViewChild('checkoutModal') modalReference!: ElementRef;
  modal!: Modal;

  isAuthenticated!: boolean;

  constructor(
    private checkoutModalService: CheckoutModalService,
    protected authenticationService: AuthenticationService
  ) {
    authenticationService.isAuthenticated().subscribe(isAuthenticated => this.isAuthenticated = isAuthenticated);
  }

  ngAfterViewInit() {
    this.modal = new Modal(this.modalReference.nativeElement);

    this.checkoutModalService.getModalState().subscribe(isVisible => {
      if (isVisible) {
        this.show();
      } else {
        this.hide();
      }
    });
  }

  show() {
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }

  requireSignup(): boolean {
    return !this.loginMemoryService.hasLoggedInBefore();
  }
}
