import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {LoginComponent} from "../login.component";
import {Modal} from "bootstrap";
import {Router} from "@angular/router";
import {LoginModalService} from "./login-modal.service";
import {MyContentRoutes} from '../../secure/my-content-routes';
import {AppRoutes} from '../../app.routes';
import {LoginMemoryService} from "../login-memory.service";

export interface LoginMetaData {
  isSignUpMode: boolean;
  requestedUrl: string | null;
  doAfterLogin: (() => void) | null
}

@Component({
  selector: 'app-login-modal',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    LoginComponent
  ],
  templateUrl: './login-modal.component.html',
  styleUrl: './login-modal.component.scss'
})
export class LoginModalComponent implements AfterViewInit {

  @ViewChild('loginModal') loginModal!: ElementRef;

  modal!: Modal;
  modalTitle: string = 'Login';

  loginMetaData!: LoginMetaData

  constructor(
    protected loginModalService: LoginModalService,
    private router: Router,
    private loginMemorySrvice: LoginMemoryService
  ) {
  }

  ngAfterViewInit() {
    this.modal = new Modal(this.loginModal.nativeElement);

    this.loginModalService.loginRequest.subscribe(value => {
      this.loginMetaData = value;
      this.modalTitle = this.loginMetaData.isSignUpMode ? 'Sign Up' : 'Login';
      this.show();
    })
  }

  show() {
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }

  onModalTitleChange(title: string) {
    this.modalTitle = title;
  }

  onLoginSuccess() {
    this.loginMemorySrvice.rememberLoginSuccess();
    this.hide();
    if (this.loginMetaData.doAfterLogin != null) {
      this.loginMetaData.doAfterLogin();
    } else if (this.loginMetaData.requestedUrl) {
      void this.router.navigate([this.loginMetaData.requestedUrl])
        .then(() => {
          window.location.reload(); // todo: this refresh should only happen when there is a "reroute" to the same page. Now it happens if a route to other pages is done
        });
    } else {
      window.location.reload();
    }
  }
}
