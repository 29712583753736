import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, ReplaySubject, Subject} from "rxjs";
import {Selection} from "../shopping-card";
import {LoginMemoryService} from "../../login/login-memory.service";

@Injectable({
  providedIn: 'root'
})
export class CheckoutModalService {

  private selectionSubject = new ReplaySubject<Selection>(1);
  private modalVisible = new BehaviorSubject<boolean>(false);

  showModal(): void {
    this.modalVisible.next(true);
  }

  hideModal(): void {
    this.modalVisible.next(false);
  }

  getModalState() {
    return this.modalVisible.asObservable();
  }

  selection() {
    return this.selectionSubject.asObservable();
  }

  select(selection: Selection) {
    this.selectionSubject.next(selection);
    this.showModal();
  }
}
