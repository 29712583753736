import {Component, Input} from '@angular/core';
import {Selection} from "./shopping-card";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {environment} from "../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import {CheckoutModalService} from "./checkout-modal/checkout-modal.service";
import {LegalService} from "../legal/legal.service";

@Component({
  selector: 'app-checkout',
  standalone: true,
  imports: [
    [CommonModule, ReactiveFormsModule],
    FormsModule
  ],
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.scss'
})
export class CheckoutComponent {

  selection!: Selection;

  protected submitError!: string;

  constructor(
    private httpClient: HttpClient,
    private checkoutModalService: CheckoutModalService,
    private legalService: LegalService
  ) {
    this.checkoutModalService.selection().subscribe(value => {
      this.selection = value;
      this.submitError = ""
    });
  }

  submit() {
    this.redirectToCheckoutSession(this.selection);
  }

  redirectToCheckoutSession(selection: Selection) {
    this.httpClient
      .get(
        environment.apiPath + '/subscriptions/checkout',
        {
          params: new HttpParams()
            .set('priceId', '' + selection.price?.id)
            .set('tryout', '' + selection.tryout)
            .set('quantity', '' + selection.quantity)
            .set('termsUrl', '' + this.legalService.licenseLink(selection.product.title)),
          responseType: 'text'
        }
      )
      .subscribe({
        next: (sessionUrl: string) => window.location.href = sessionUrl,
        error: (error) => {
          console.log(error.error);
          this.submitError = error.error + '.<br><br>' + 'Please, contact <a href="mailto:root@keldysh.io">root&#64;keldysh.io</a> for further assistance.';
        }
      })
  }
}
