import {AfterViewInit, Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {ActionConfirmationModalService, ModalState} from "./action-confirmation-modal.service";
import {Subscription} from "rxjs";
import {CommonModule} from "@angular/common";
import {CheckoutComponent} from "../checkout/checkout.component";
import {LoginComponent} from "../login/login.component";
import {Modal} from "bootstrap";
import {state} from "@angular/animations";

@Component({
  selector: 'app-action-confirmation-modal',
  standalone: true,
  imports: [CommonModule, CheckoutComponent, LoginComponent],
  templateUrl: './action-confirmation-modal.component.html',
  styleUrl: './action-confirmation-modal.component.scss'
})
export class ActionConfirmationModalComponent implements AfterViewInit, OnDestroy {

  @ViewChild('actionConfirmationModal') modalReference!: ElementRef;
  modal!: Modal;

  modalState: ModalState | null = null;
  private modalSubscription!: Subscription;

  constructor(private modalService: ActionConfirmationModalService) {
  }

  ngAfterViewInit(): void {
    this.modal = new Modal(this.modalReference.nativeElement);
    this.modalSubscription = this.modalService.modalState$.subscribe(state => {
      this.modalState = state;
      this.showModal();
    });
  }

  ngOnDestroy() {
    this.modalSubscription?.unsubscribe();
  }

  showModal() {
    this.modal.show();
  }

  hideModal() {
    this.modal.hide();
  }

  submit() {
    this.modalState?.submitAction();
    this.hideModal();
  }
}
