<div class="modal fade" id="checkoutModal" #checkoutModal tabindex="-1" aria-labelledby="checkoutModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="checkoutModalLabel">Checkout</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div *ngIf="!isAuthenticated">
        <div class="modal-body">
          <p class="py-3">Please, sign up and log in before proceeding to checkout.</p>
          <app-login [isSignUpMode]="requireSignup()"></app-login>
        </div>
      </div>
      <div *ngIf="isAuthenticated">
        <div class="modal-body">
          <app-checkout></app-checkout>
        </div>
      </div>
    </div>
  </div>
</div>
